.alignfull {
  margin: 0 calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.para--sf_listings_map {
  margin: 50px 0;
}
.map-wrapper {
  position: relative;
  padding-bottom: 0;
  //min-height: calc(100vh - 270px) !important;

  @media only screen and (max-width: 767px) {
    padding-bottom: 6.5rem;
    min-height: 360px;
  }

  .potnets-map-lbl {
    background-color: #f9f5f2;
    padding: 2px 4px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    font-weight: 600;
  }

  .info-msg {
    font-size: 1rem;
    position: absolute;
    text-align: center;
    top: 0px;
    z-index: 10;
    max-width: 500px;
    left: 45%;
    background-color: rgba(68,68,68,.85);
    padding: 2px 12px;
    border-radius: 3px;
    margin: 0;
    color: white;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .legend-color {
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 486px;
    border: solid 1px #d3d3d3;

    .item {
      padding-left: 30px;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 6px;
      background-position: 8px center!important;
      @media only screen and (min-width: 768px) {
        display: table-cell;
      }
    }

    .item.preowned {
      background: url("../public/images/maps/pre-owned-homes-marker.png");
      background-repeat: no-repeat;
      background-size: 16px;
    }

    .item.p-lot {
      background: url("../public/images/maps/lots-marker.png");
      background-repeat: no-repeat;
      background-size: 16px;
    }

    .item.p-new {
      background: url("../public/images/maps/new-homes-marker.png");
      background-repeat: no-repeat;
      background-size: 16px;
    }
  }
}
